import classes from './Footer.module.css';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { buildEtherscanAddressLink } from '../../utils/etherscan';
import { externalURL, ExternalURL } from '../../utils/externalURL';
import config from '../../config';
import Link from '../Link';

const Footer = () => {
  const twitterURL = externalURL(ExternalURL.twitter);
  const etherscanURL = buildEtherscanAddressLink(config.addresses.bitstraysToken);

  const location = useLocation();
  const useGreyBg = useAppSelector(state => state.application.stateBackgroundColor);

  const determineBgColor = (path: string) => {
    if (path.startsWith('/santa')) {
      return 'red';
    }
    return location.pathname === '/' ||
    location.pathname.startsWith('/auction/') ||
    location.pathname.startsWith('/santa') ||
    location.pathname.startsWith('/playground') ||
    location.pathname.startsWith('/bitstray/')
      ? 'white'
      : useGreyBg
      ? '#d5d7e1'
      : '#e1d7d5';
  }

  const bgColor = determineBgColor(location.pathname)
    

  return (
    <div className={classes.wrapper} style={{ backgroundColor: bgColor }}>
      <Container className={classes.container}>
        <footer className={classes.footerSignature}>
          <Link text="twitter" url={twitterURL} leavesPage={true} />
          <Link text="etherscan" url={etherscanURL} leavesPage={true} />
        </footer>
      </Container>
    </div>
  );
};
export default Footer;
