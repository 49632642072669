import { useAppSelector } from '../../hooks';
import ShortAddress from '../ShortAddress';
import classes from './NavBar.module.css';
import logo from '../../assets/logo.svg';
import opensea from '../../assets/opensea.svg';
import etherscan from '../../assets/etherscan-logo-circle.svg';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useEthers } from '@usedapp/core';
import WalletConnectModal from '../WalletConnectModal';
import { HashLink as Link } from 'react-router-hash-link';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Twitter, LightningFill, GiftFill } from 'react-bootstrap-icons';
import clsx from 'clsx';
import { CHAIN_ID } from '../../config';
import { ExternalURL, externalURL } from '../../utils/externalURL';
import { isEnabled } from '../Feature';

const NavBar = () => {
  const activeAccount = useAppSelector(state => state.account.activeAccount);
  const { deactivate } = useEthers();

  const stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);
  const history = useHistory();
  //const treasuryBalance = useEtherBalance(config.addresses.bitstraysDaoExecutor);
  //const daoEtherscanLink = buildEtherscanAddressLink(config.addresses.bitstraysDaoExecutor);

  const [showConnectModal, setShowConnectModal] = useState(false);

  const showModalHandler = () => {
    setShowConnectModal(true);
  };
  const hideModalHandler = () => {
    setShowConnectModal(false);
  };

  const connectedContent = (
    <>
      <Nav.Item>
        <Nav.Link className={clsx(classes.bitstraysNavLink, classes.addressNavLink)} disabled>
          <span className={classes.greenStatusCircle} />
          <span>{activeAccount && <ShortAddress address={activeAccount} />}</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className={clsx(classes.bitstraysNavLink, classes.disconnectBtn)}
          onClick={() => {
            setShowConnectModal(false);
            deactivate();
            setShowConnectModal(false);
          }}
        >
          DISCONNECT
        </Nav.Link>
      </Nav.Item>
    </>
  );

  const disconnectedContent = (
    <>
      <Nav.Link
        className={clsx(classes.bitstraysNavLink, classes.connectBtn, classes.blinkingThing)}
        onClick={showModalHandler}
      >
        CONNECT WALLET
      </Nav.Link>
    </>
  );

  const useStateBg =
    history.location.pathname === '/' ||
    history.location.pathname.includes('/bitstray') ||
    history.location.pathname.includes('/santa') ||
    history.location.pathname.includes('/auction');

  return (
    <>
      {showConnectModal && activeAccount === undefined && (
        <WalletConnectModal onDismiss={hideModalHandler} />
      )}
      <Navbar expand="lg" style={{ backgroundColor: `${useStateBg ? stateBgColor : ''}` }}>
        <Container>
          <Navbar.Brand as={Link} to="/" className={classes.navBarBrand}>
            <img
              src={logo}
              width="85"
              height="85"
              className="d-inline-block align-middle"
              alt="Bitstrays DAO logo"
            />
          </Navbar.Brand>
          {Number(CHAIN_ID) !== 1 && (
            <Nav.Item>
              <LightningFill className={classes.testnetImg} />
              TESTNET
            </Nav.Item>
          )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link
              className={classes.bitstraysNavLink}
              as={Link}
              to="/#home"
              aria-label="home"
            >
              HOME
            </Nav.Link>
            { isEnabled('santa') ? <Nav.Link
                className={classes.bitstraysNavLink}
                as={Link}
                to="/santa"
                aria-label="santa"
              >
                <GiftFill />
              </Nav.Link> : <></>
            }
            <Nav.Link
              className={classes.bitstraysNavLink}
              as={Link}
              to="/#faq"
              aria-label="FAQ"
            >
              FAQ
            </Nav.Link>
            <Nav.Link
              href={externalURL(ExternalURL.twitter)}
              className={classes.bitstraysNavLink}
              target="_blank"
              rel="noreferrer"
              aria-label="twitter"
            >
              <Twitter />
            </Nav.Link>
            <Nav.Link
              href={externalURL(ExternalURL.opensea)}
              className={classes.bitstraysNavLink}
              target="_blank"
              rel="noreferrer"
              aria-label="open sea"
            >
              <img
                src={opensea}
                width="80"
                // height="17"
                className="d-inline-block align-middle"
                alt="Open Sea"
              />
            </Nav.Link>
            <Nav.Link
              href={externalURL(ExternalURL.etherscan)}
              className={classes.bitstraysNavLink}
              target="_blank"
              rel="noreferrer"
              aria-label="etherscan"
            >
              <img
                src={etherscan}
                width="20"
                // height="17"
                className="d-inline-block align-middle"
                alt="etherscan"
              /> CONTRACT
            </Nav.Link>
            {activeAccount ? connectedContent : disconnectedContent}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
