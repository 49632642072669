import { useContractCall } from '@usedapp/core';
import { BigNumber as EthersBN, utils } from 'ethers';
import { BitstraysSeederABI } from '@bitstrays/contracts';
import { IBitstraySeed } from './bitstrayToken'
import config from '../config';


const abi = new utils.Interface(BitstraysSeederABI);

export const useBitstraySantasSeedCandiate = (bitstrayId: EthersBN) => {
    const seed = useContractCall<[IBitstraySeed]>({
        abi,
        address: config.addresses.bitstraySantasSeeder,
        method: 'generateSeed',
        args: [bitstrayId, config.addresses.bitstraysDescriptor],
    });
    return seed;
};