"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractAddressesForChainOrThrow = void 0;
const types_1 = require("./types");
const chainIdToAddresses = {
    [types_1.ChainId.Mainnet]: {
        bitstraysToken: '0x73a8CE3f662a1e66563D05606BC5a8aB71a4D924',
        bitstraySantasToken: '0x6Ae49Cc128226BbEA767b8a91db6aeA9Ae3F1816',
        bitstraysSeeder: '0x6a0310FC7D9A9963C97E756947d1Aa15a7029b58',
        bitstraySantasSeeder: '0x6a0b01Bdf6484A86628A9bcEfE0A23692dde11FE',
        bitstraysDescriptor: '0x9da7811ef73222393077730e7b4853a01EEDe1c3',
        nftDescriptor: '0x94523e4741a1F8Fb3707E01BDe572132582f3cc7',
        bitstraysDaoExecutor: '0x0BC3807Ec262cB779b38D65b38158acC3bfedE10',
        bitstraysDAOProxy: '0x6f3E6272A167e8AcCb32072d08E0957F9c79223d',
        bitstraysDAOLogicV1: '0xa43aFE317985726E4e194eb061Af77fbCb43F944',
    },
    [types_1.ChainId.Rinkeby]: {
        bitstraysToken: '0xF317FB4c432C1767121C9629c2C91404772e2487',
        bitstraySantasToken: '0xD322dcbc031415290753c187184c1DE6E4E8a08F',
        bitstraysSeeder: '0xa7b399406f41929668E2800A75aeA60966A6BA6a',
        bitstraySantasSeeder: '0x191435b7C70b2F7Fa9cc34111838053dc0c588f0',
        bitstraysDescriptor: '0x8B66A5df6Bda8136dD65Ae7D889Bb08D8E1866ac',
        nftDescriptor: '0x29372C576A633Fe618861950230A575074E33FEE',
        bitstraysDaoExecutor: '0x6F3940820288855418B7ef8E33a2eC23d9DeD59B',
        bitstraysDAOProxy: '0xd1C753D9A23eb5c57e0d023e993B9bd4F5086b04',
        bitstraysDAOLogicV1: '0xdF05F2D3276F3F3fA00296702e4cf7190B78F6F9',
    },
    [types_1.ChainId.Local]: {
        bitstraysToken: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
        bitstraySantasToken: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707',
        bitstraysSeeder: '0x9fe46736679d2d9a65f0992f2272de9f3c7fa6e0',
        bitstraySantasSeeder: '0xcf7ed3acca5a467e9e704c703e8d87f634fb0fc9',
        bitstraysDescriptor: '0xe7f1725e7734ce288f8367e1bb143e90bb3f0512',
        nftDescriptor: '0x5fbdb2315678afecb367f032d93f642f64180aa3',
        bitstraysDaoExecutor: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
        bitstraysDAOProxy: '0x0165878A594ca255338adfa4d48449f69242Eb8F',
        bitstraysDAOLogicV1: '0x5fc8d32690cc91d4c39d9d3abcbd16989f875707',
    },
};
/**
 * Get addresses of contracts that have been deployed to the
 * Ethereum mainnet or a supported testnet. Throws if there are
 * no known contracts deployed on the corresponding chain.
 * @param chainId The desired chainId
 */
const getContractAddressesForChainOrThrow = (chainId) => {
    if (!chainIdToAddresses[chainId]) {
        throw new Error(`Unknown chain id (${chainId}). No known contracts have been deployed on this chain.`);
    }
    return chainIdToAddresses[chainId];
};
exports.getContractAddressesForChainOrThrow = getContractAddressesForChainOrThrow;
