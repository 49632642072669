import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SalesState {
  isSaleActive: boolean | false;
  isPreSaleActive: boolean | false;
  lastSaleId: Number | 0;
}

const initialState: SalesState = {
    isSaleActive: false,
    isPreSaleActive : false,
    lastSaleId: 0
};

export const salesSlice = createSlice({
  name: 'sales',
  initialState: initialState,
  reducers: {
    setIsSaleActive: (state, action: PayloadAction<boolean>) => {
      state.isSaleActive = action.payload;
    },
    setIsPreSaleActive: (state, action: PayloadAction<boolean>) => {
      state.isPreSaleActive = action.payload;
    },
    setLastSaleId: (state, action: PayloadAction<Number>) => {
      state.lastSaleId = action.payload;
    },
  },
});

export const {
    setIsSaleActive,
    setIsPreSaleActive,
    setLastSaleId
} = salesSlice.actions;

export default salesSlice.reducer;
