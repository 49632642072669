import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './AboutTheProject.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';

const AboutTheProject = () => {
  const NounsLink = (
    <Link text="Nouns" url="https://nouns.wtf/" leavesPage={true} />
  );
  return (
    <Section bgColor="white" fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>About the project</h1>
          <p>Bitstrays are pure on-chain NFT profile picture art. Bitstrays are straying animals who are looking for a home.
            We believe that pure on-chain avatars will become a real thing once we see some of the traditional NFT projects
            with links to centralized API's struggle to stand the test of time.</p>
          <p>This project was inspired by {NounsLink} and the public domain license.</p>
        </div>
        <Accordion flush defaultActiveKey="0">
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Summary</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>Bitstrays are on-chain avatars</li>
                <li>There will be 10,000 total NFTs (0-9999)</li>
                <li>Our pixel artwork is generative and stored on-chain</li>
                <li>Attributes (trait metadata) are stored on-chain</li>
                <li>All character traits are generated at random (pseudorandomness)</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Bitstray Traits</Accordion.Header>
            <Accordion.Body>
              <p>
                Bitstrays are generated randomly based on Ethereum block hashes.
                Each Bitstray has a 'kind' trait which is derived
                from the head. All traits are equally likely except for some rarer
                heads.<br />
                We have the following traits:
              </p>
              <ul>
                <li>arms (4) </li>
                <li>backgrounds (6) </li>
                <li>eyes (41)</li>
                <li>heads (113) </li>
                <li>motives (67) </li>
                <li>mouths (20)</li>
                <li>shirt (11)</li>
              </ul>
              There are 273,161,680 different Bitstrays combination possible excluding the background colors.
              We are excited to learn which of the will be adoped (minted) forever on-chain!
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default AboutTheProject;
