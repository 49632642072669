import {
  getEtherscanContractUrl
} from '../utils/etherscan';
export enum ExternalURL {
  twitter,
  notion,
  discourse,
  opensea,
  etherscan
}

export const externalURL = (externalURL: ExternalURL) => {
  switch (externalURL) {
    case ExternalURL.twitter:
      return 'https://twitter.com/bitstrays';
    case ExternalURL.notion:
      return 'https://bitstrays.notion.site/Explore-Bitstrays-a2a9dceeb1d54e10b9cbf3f931c2266f';
    case ExternalURL.discourse:
      return 'https://discourse.bitstrays.wtf/';
    case ExternalURL.opensea:
      return 'https://opensea.io/collection/bitstrays';
    case ExternalURL.etherscan:
      return getEtherscanContractUrl()
  }
};
