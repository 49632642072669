import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SantaState {
  isMintActive: boolean | false;
  isGiftingActive: boolean | false;
}

const initialState: SantaState = {
    isMintActive: false,
    isGiftingActive: false
};

export const santaSlice = createSlice({
  name: 'santa',
  initialState: initialState,
  reducers: {
    setIsMintActive: (state, action: PayloadAction<boolean>) => {
      state.isMintActive = action.payload;
    },
    setIsGiftingActive: (state, action: PayloadAction<boolean>) => {
      state.isGiftingActive = action.payload;
    }
  },
});

export const {
  setIsMintActive,
  setIsGiftingActive
} = santaSlice.actions;

export default santaSlice.reducer;