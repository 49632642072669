import { BigNumber } from 'ethers';

import Banner from '../../components/Banner';
import HistoryCollection from '../../components/HistoryCollection';
import AboutTheProject from '../../components/AboutTheProject';
import AboutTheTeam  from '../../components/AboutTheTeam';
import FAQ from '../../components/FAQ';
import Hero from '../../components/Hero';
import { useBitstrayTotalSupply, useBitstrayPrice } from '../../wrappers/bitstrayToken';
import { useAppSelector } from '../../hooks';

interface MainPageProps {
  initialAuctionId?: number;
}

const MainPage: React.FC<MainPageProps> = props => {
  const totalSupply = useBitstrayTotalSupply();
  const mintPrice = useBitstrayPrice();
  const lastSaleId = useAppSelector(state => state.sales.lastSaleId);

  return (
    <>
      <Hero
        totalSupply={ totalSupply?.toNumber() || 0 }
        mintPrice = { mintPrice || BigNumber.from(0.5) }
      />
      <Banner/>
      {lastSaleId && (
        <HistoryCollection latestBitstrayId={BigNumber.from(lastSaleId)} historyCount={10} />
      )}
      
      <AboutTheProject />
      <FAQ />
      <AboutTheTeam />
    </>
  );
};
export default MainPage;
