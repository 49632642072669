// @ts-nocheck
import { connectContractToSigner, useEthers, useContractFunction } from '@usedapp/core';
import React, { useState, useCallback, useRef } from 'react';
import { Row, Container, Col, InputGroup, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BigNumber as EthersBN, errors as EthersErrors } from 'ethers';
import { IBitstraySeed, BitstraySantasContractFunction } from '../../wrappers/bitstraySantasToken';
import { Bootstrap4Toggle } from 'react-bootstrap-toggle-switch'

import { BitstraySantasTokenFactory } from '@bitstrays/sdk';
import { StandaloneCandidateBitstraySantaWithSeed } from '../StandaloneBitstray';
import { LoadingBitstray } from '../Bitstray';
import { Gift } from 'react-bootstrap-icons';
import Ribbon from '../../assets/icons/Ribbon.svg';
import { setIsGiftingActive } from '../../state/slices/santa';
import { AlertModal, setAlertModal, setStateBackgroundColor } from '../../state/slices/application';
import config from '../../config';
import classes from './ChristmasMint.module.css';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { beige, grey, green, blue, creme, pink, purple  } from '../../utils/bitstraysBgColors';

interface ChristmasMintProps {
  totalSupply: number;
}

const ChristmasMint: React.FC<ChristmasMintProps> = props => {
  //const isMintActive = useAppSelector(state => state.santa.isMintActive);
  const { totalSupply } = props;
  const { library } = useEthers();

  const [mintInput, setMintInput] = useState('');
  const mintInputRef = useRef<HTMLInputElement>(null);

  const bitstraySantasContract = new BitstraySantasTokenFactory().attach(
    config.addresses.bitstraySantasToken,
  );

  const { send: placeMint } = useContractFunction(
    bitstraySantasContract,
    BitstraySantasContractFunction.publicMint,
  );

  const { send: giftTo } = useContractFunction(
    bitstraySantasContract,
    BitstraySantasContractFunction.giftTo,
  );

  const bitstraySantaCandiateId = EthersBN.from(totalSupply)

  //const history = useHistory();
  const dispatch = useAppDispatch();

  const loadedBitstrayHandler = (seed: IBitstraySeed) => {
    let color = grey
    switch (seed.background) {
      case 0:
        color = grey;
        break;
      case 1:
        color = beige;
        break;
      case 2:
        color = green;
        break;
      case 3:
        color = blue;
        break;
      case 4:
        color = creme;
        break;
      case 5:
        color = pink;
        break;
      case 6:
        color = purple;
        break;
      default:
        color = grey;
    }
    dispatch(setStateBackgroundColor(color));
  };

  const mintInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setMintInput(event.target.value);
  };

  const setModal = useCallback((modal: AlertModal) => dispatch(setAlertModal(modal)), [dispatch]);

  const parseError = (error: any) => {
    try {
      console.log(JSON.stringify(error))
      const chainError = error.error ? error.error : null
      if (error.message === 'Internal JSON-RPC error.') {
        const message = error.data ? error.data.message.split("'")[1] : error.message;
        return {
          message: message,
          errorTitle: "Mint Function Error"
        }
      };
      if (chainError) {
        let returnError = {
          message: 'Error',
          errorTitle: "Generic Web3 Error"
        }
        let messageParts = chainError.message.split('Error: ')
        switch (error.code) {
          case EthersErrors.INSUFFICIENT_FUNDS:
            messageParts = chainError.message.split('err: ')
            returnError.message = messageParts[1]
            returnError.errorTitle = "Mint Function Error"
            break;
          case EthersErrors.UNPREDICTABLE_GAS_LIMIT:
            returnError.message = messageParts[1]
            returnError.errorTitle = "Mint Function Error"
            break;
          case EthersErrors.INVALID_ARGUMENT:
            returnError.message = chainError.message
            returnError.errorTitle = "Mint Function Error"
            break;
          default:
            returnError.message = chainError.message
            returnError.errorTitle = "Smart Contract Error"
            console.log(chainError)
            break;
        }
        return returnError
      };
      // all other error cases
      return {
        message: <p>{error.message.split(' (')[0]}</p>,
        errorTitle: 'Error'
      }
    } catch (error: any) {
      console.log(error)
      return {
        message: <p>{error.message}</p>,
        errorTitle: 'Error'
      }
    }
  };
  const bitstraySantaContent = (
    <div className={classes.bitstrayWrapper}>
      <StandaloneCandidateBitstraySantaWithSeed
        bitstrayId={bitstraySantaCandiateId}
        onLoadSeed={loadedBitstrayHandler}
        shouldLinkToProfile={false}
      />
    </div>
  );

  const toggleGiftHander = (checked: boolean) => {
    dispatch(setIsGiftingActive(checked));
  }
  
  const placeGiftHandler = async () => {
    if (!mintInputRef.current || !mintInputRef.current.value) {
      setModal({
        title: 'Invalid Gifting Address',
        message: 'Please provide an address to gift Bitstray Santa',
        show: true,
      });
      return;
    }
    const address = mintInputRef.current.value;
    const contract = connectContractToSigner(bitstraySantasContract, undefined, library);
    try {
      const gasLimit = await contract.estimateGas.giftTo(address);
      giftTo(address, {
        gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
      });

    } catch (error: any) {
      const { message, errorTitle } = parseError(error)
      setModal({
        title: errorTitle,
        message: message,
        show: true,
      });
    }
  }

  const placeMintHandler = async () => {
    const contract = connectContractToSigner(bitstraySantasContract, undefined, library);
    try {
      const gasLimit = await contract.estimateGas.publicMint();
      placeMint({
        gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
      });

    } catch (error: any) {
      const { message, errorTitle } = parseError(error)
      setModal({
        title: errorTitle,
        message: message,
        show: true,
      });
    }
  }

  const loadingBitstray = (
    <div className={classes.bitstrayWrapper}>
      <LoadingBitstray />
    </div>
  );

  const giftButton = (
    <Row>
    <OverlayTrigger
      key='left'
      placement='right'
      overlay={
        <Tooltip id={`tooltip-top`}>
          <strong>Mint one</strong> for yourself or <strong>Gift one</strong> to your friends or familiy.
        </Tooltip>
      }>
      <Button onClick={placeGiftHandler} type="button" variant="danger" size="lg" className={[classes.btn3d, classes.btnDanger, classes.mintBtn].join(' ')}>
              <Gift /><br/> Gift It!
      </Button>
    </OverlayTrigger> 
    </Row>
  );

  const mintButton = (
    <Row>
      <OverlayTrigger
      key='left'
      placement='right'
      overlay={
        <Tooltip id={`tooltip-top`}>
          <strong>Mint one</strong> for yourself or <strong>Gift one</strong> to your friends or familiy.
        </Tooltip>
      }>
      <Button onClick={placeMintHandler} type="button" variant="danger" size="lg" className={[classes.btn3d, classes.btnDanger, classes.mintBtn].join(' ')}>
                <Gift /><br/> Mint It!
      </Button>
      </OverlayTrigger>
    </Row>
  );

  const addressInput = (
    <InputGroup>
                  <Form.Control
                    className={classes.addressInput}
                    type="string"
                    min=""
                    onChange={mintInputHandler}
                    placeholder="Gifting ETH Address"
                    ref={mintInputRef}
                    value={mintInput}
                    size="lg"
                  />
    </InputGroup>
  )

  const ChristmasMintBtn = (
    <div>
      <Col lg={{ span: 4 }} className={classes.gift}>
        <Row>
          <img src={Ribbon} className={classes.RibbonIcon} alt='Ribbon' />
        </Row>
        {mintButton}
      </Col>
    </div>
  );

  const ChristmasGiftBtn = (
    <div>
      <Col lg={{ span: 4 }} className={classes.gift}>
        <Row>
          <img src={Ribbon} className={classes.RibbonIcon} alt='Ribbon' />
        </Row>
          {giftButton}
      </Col>
    </div>
  );

  let stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);
  let isGiftingActive = useAppSelector(state => state.santa.isGiftingActive);
  return (
    <div style={{ backgroundColor: stateBgColor }}>
      {/* <div className={classes.snowflakes} >
        {
          ['b','i','t','s','t','r','a','i', 's'].map(()=><div className={classes.snowflake}>❅</div>)
        }
      </div> */}
      <Container fluid="lg">
        <Row>
          <div className="snow"></div>
          <Col lg={{ span: 6 }} className={classes.bitstrayContentCol}>
            {bitstraySantaContent ? bitstraySantaContent : loadingBitstray}
          </Col>
          <Col lg={{ span: 6 }}>
            <h1 className={classes.title}>Merry Bitstray Xmas {totalSupply}/500</h1>
            <div><Bootstrap4Toggle onLabel='Gift' offLabel='Mint' onChange={toggleGiftHander} ></Bootstrap4Toggle> </div>
            {isGiftingActive? ChristmasGiftBtn: ChristmasMintBtn}
            <div className={classes.addressPlaceholder}>{isGiftingActive? addressInput: ''}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ChristmasMint;
