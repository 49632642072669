import React from 'react';
import { Col } from 'react-bootstrap';

import classes from './BitstrayInfoCard.module.css';

import _AddressIcon from '../../assets/icons/Address.svg';

import BitstrayInfoRowHolder from '../BitstrayInfoRowHolder';
import BitstrayInfoRowButton from '../BitstrayInfoRowButton';
//import { useHistory } from 'react-router';

import config from '../../config';
import { buildEtherscanAddressLink } from '../../utils/etherscan';
import { buildOpenSeaAddressLink } from '../../utils/opensea';
//import { useDispatch } from 'react-redux';

interface BitstrayInfoCardProps {
  bitstrayId: number;
}

const BitstrayInfoCard: React.FC<BitstrayInfoCardProps> = props => {
  const { bitstrayId } = props;
  //const history = useHistory();
  //const dispatch = useDispatch();

  const etherscanBaseURL = buildEtherscanAddressLink(config.addresses.bitstraysToken);
  const openSeaBaseURL = buildOpenSeaAddressLink(config.addresses.bitstraysToken);
  // eslint-disable-next-line no-restricted-globals
  const etherscanButtonClickHandler = () => (location.href = `${etherscanBaseURL}/${bitstrayId}`);
  // eslint-disable-next-line no-restricted-globals
  const openSeaButtonClickHandler = () => (location.href = `${openSeaBaseURL}/${bitstrayId}`);

  return (
    <>
      <Col lg={12}>
        <div className={classes.bitstrayInfoHeader}>
          <h3>Profile</h3>
          <h2>Bitstray {bitstrayId}</h2>
        </div>
      </Col>
      <Col lg={12} className={classes.bitstrayInfoRow}>
        <BitstrayInfoRowHolder bitstrayId={bitstrayId} />
      </Col>
      <Col lg={12} className={classes.bitstrayInfoRow}>
      <BitstrayInfoRowButton
          iconImgSource={_AddressIcon}
          btnText={'OpenSea'}
          onClickHandler={openSeaButtonClickHandler}
        />
        <BitstrayInfoRowButton
          iconImgSource={_AddressIcon}
          btnText={'Etherscan'}
          onClickHandler={etherscanButtonClickHandler}
        />
      </Col>
    </>
  );
};

export default BitstrayInfoCard;
