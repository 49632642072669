import { ChainId } from '@usedapp/core';
import { CHAIN_ID } from '../config';

const getBaseURL = (network: ChainId) => {
    switch (network) {
      case ChainId.Rinkeby:
        return 'https://testnets.opensea.io/';
      default:
        return 'https://opensea.io/';
    }
  };

const BASE_URL = getBaseURL(CHAIN_ID);

export const buildOpenSeaAddressLink = (address: string): string => {
    const path = `assets/${address}`;
    return new URL(path, BASE_URL).toString();
  };


