import classes from './Bitstray.module.css';
import React from 'react';
import loadingBitstray from '../../assets/loading-frog-bitstray.gif';
import Image from 'react-bootstrap/Image';

export const LoadingBitstray = () => {
  return (
    <div className={classes.imgWrapper}>
      <Image className={classes.img} src={loadingBitstray} alt={'loading bitstray'} fluid />
    </div>
  );
};

const Bitstray: React.FC<{
  imgPath: string;
  alt: string;
  className?: string;
  wrapperClassName?: string;
}> = props => {
  const { imgPath, alt, className, wrapperClassName } = props;
  return (
    <div className={`${classes.imgWrapper} ${wrapperClassName}`}>
      <Image
        className={`${classes.img} ${className}`}
        src={imgPath ? imgPath : loadingBitstray}
        alt={alt}
        fluid
      />
    </div>
  );
};

export default Bitstray;
