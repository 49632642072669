import { useContractCall, useEthers } from '@usedapp/core';
import { BigNumber as EthersBN, utils } from 'ethers';
import { BitstraysTokenABI } from '@bitstrays/contracts';
import config from '../config';

export enum BitstrayContractFunction {
  publicMint = 'publicMint',
}

interface BitstrayToken {
  name: string;
  attributes: BitstrayTrait[]
  description: string;
  image: string;
}

interface BitstrayTrait {
  trait_type: string;
  value: string;
}

export interface IBitstraySeed {
  background: number;
  arms: number;
  shirt: number;
  motive: number;
  head: number;
  eyes: number;
  mouth: number;
}

const abi = new utils.Interface(BitstraysTokenABI);

export const useBitstrayToken = (bitstrayId: EthersBN) => {
  const [bitstray] =
    useContractCall<[string]>({
      abi,
      address: config.addresses.bitstraysToken,
      method: 'dataURI',
      args: [bitstrayId],
    }) || [];

  if (!bitstray) {
    return;
  }

  const bitstrayImgData = bitstray.split(';base64,').pop() as string;
  const json: BitstrayToken = JSON.parse(atob(bitstrayImgData));

  return json;
};

export const useBitstrayTotalSupply = () => {
  const [totalSupply] = useContractCall<[EthersBN]>({
    abi,
    address: config.addresses.bitstraysToken,
    method: 'totalSupply'
  }) || [];
  return totalSupply;
};

export const useBitstrayPrice = () => {
  const [bitstrayPrice] = useContractCall<[EthersBN]>({
    abi,
    address: config.addresses.bitstraysToken,
    method: 'bitstrayPrice'
  }) || [EthersBN.from(0)];
  return bitstrayPrice;
};

export const useBitstrayPublicMint = (amount: EthersBN, quantity: EthersBN, proof: string[]) => {
  const payable = {value: Number(utils.formatEther(EthersBN.from(amount.toString())))}
  const tokenId = useContractCall<EthersBN>({
    abi,
    address: config.addresses.bitstraysToken,
    method: 'publicMint',
    args: [quantity, proof, payable],
  });
  return tokenId;
};

export const useBitstraySeed = (bitstrayId: EthersBN) => {
  const seed = useContractCall<IBitstraySeed>({
    abi,
    address: config.addresses.bitstraysToken,
    method: 'seeds',
    args: [bitstrayId],
  });
  return seed;
};

export const useUserVotes = (): number | undefined => {
  const { account } = useEthers();
  const [votes] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.bitstraysToken,
      method: 'getCurrentVotes',
      args: [account],
    }) || [];
  return votes?.toNumber();
};

export const useUserDelegatee = (): string | undefined => {
  const { account } = useEthers();
  const [delegate] =
    useContractCall<[string]>({
      abi,
      address: config.addresses.bitstraysToken,
      method: 'delegates',
      args: [account],
    }) || [];
  return delegate;
};

export const useUserVotesAsOfBlock = (block: number | undefined): number | undefined => {
  const { account } = useEthers();

  // Check for available votes
  const [votes] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.bitstraysToken,
      method: 'getPriorVotes',
      args: [account, block],
    }) || [];
  return votes?.toNumber();
};
