"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomBitstraySeed = exports.getBitstrayData = void 0;
const image_data_json_1 = require("./image-data.json");
const { arms, shirts, motives, heads, eyes, mouths } = image_data_json_1.images;
/**
 * Get encoded part and background information using a Bitstray seed
 * @param seed The Bitstray seed
 */
const getBitstrayData = (seed) => {
    return {
        parts: [
            arms[seed.arms],
            shirts[seed.shirt],
            motives[seed.motive],
            heads[seed.head],
            eyes[seed.eyes],
            mouths[seed.mouth],
        ],
        background: image_data_json_1.bgcolors[seed.background],
    };
};
exports.getBitstrayData = getBitstrayData;
/**
 * Generate a random Bitstray seed
 * @param seed The Bitstray seed
 */
const getRandomBitstraySeed = () => {
    return {
        background: Math.floor(Math.random() * image_data_json_1.bgcolors.length),
        arms: Math.floor(Math.random() * arms.length),
        shirt: Math.floor(Math.random() * shirts.length),
        motive: Math.floor(Math.random() * motives.length),
        head: Math.floor(Math.random() * heads.length),
        eyes: Math.floor(Math.random() * eyes.length),
        mouth: Math.floor(Math.random() * mouths.length),
    };
};
exports.getRandomBitstraySeed = getRandomBitstraySeed;
