import { ImageData as data, getBitstrayData } from '@bitstrays/assets';
import { buildSVG } from '@bitstrays/sdk';
import { BigNumber as EthersBN } from 'ethers';
import { IBitstraySeed, useBitstraySeed } from '../../wrappers/bitstrayToken';
import { useBitstraySeedCandiate } from '../../wrappers/bitstraysSeeder';
import { useBitstraySantasSeedCandiate } from '../../wrappers/bitstraySantasSeeder';
import Bitstray from '../Bitstray';
import { Link } from 'react-router-dom';
import classes from './StandaloneBitstray.module.css';

interface StandaloneBitstrayProps {
  bitstrayId: EthersBN;
}

interface StandaloneBitstrayWithSeedProps {
  bitstrayId: EthersBN;
  onLoadSeed?: (seed: IBitstraySeed) => void;
  shouldLinkToProfile: boolean;
}

interface StandaloneCandidateBitstrayWithSeedProps {
  bitstrayId: EthersBN;
  onLoadSeed?: (seed: IBitstraySeed) => void;
}

const getBitstray = (bitstrayId: string | EthersBN, seed: IBitstraySeed) => {
  const id = bitstrayId.toString();
  const name = `Bitstray ${id}`;
  const description = `Bitstray ${id} is a member of the Bitstrays DAO`;
  const { parts, background } = getBitstrayData(seed);
  const image = `data:image/svg+xml;base64,${btoa(buildSVG(parts, data.palette, background))}`;

  return {
    name,
    description,
    image,
  };
};

const StandaloneBitstray: React.FC<StandaloneBitstrayProps> = (props: StandaloneBitstrayProps) => {
  const { bitstrayId } = props;
  const seed = useBitstraySeed(bitstrayId);
  const bitstray = seed && getBitstray(bitstrayId, seed);

  return (
    <Link to={'/bitstray/' + bitstrayId.toString()} className={classes.clickableBitstray}>
      <Bitstray imgPath={bitstray ? bitstray.image : ''} alt={bitstray ? bitstray.description : 'Bitstray'} />
    </Link>
  );
};

export const StandaloneCandidateBitstrayWithSeed: React.FC<StandaloneCandidateBitstrayWithSeedProps> = (
  props: StandaloneCandidateBitstrayWithSeedProps,
) => {
  const { bitstrayId, onLoadSeed } = props;

  const seedArray = useBitstraySeedCandiate(bitstrayId);

  if (!seedArray || !bitstrayId || !onLoadSeed) return <Bitstray imgPath="" alt="Bitstray" />;

  onLoadSeed(seedArray[0]);
  const { image, description } = getBitstray(bitstrayId, seedArray[0]);
  
  const bitstray = <Bitstray imgPath={image} alt={description} />;
  return bitstray;
};

export const StandaloneCandidateBitstraySantaWithSeed: React.FC<StandaloneCandidateBitstrayWithSeedProps> = (
  props: StandaloneCandidateBitstrayWithSeedProps,
) => {
  const { bitstrayId, onLoadSeed } = props;

  const seedArray = useBitstraySantasSeedCandiate(bitstrayId);

  if (!seedArray || !bitstrayId || !onLoadSeed) return <Bitstray imgPath="" alt="Bitstray" />;

  onLoadSeed(seedArray[0]);
  const { image, description } = getBitstray(bitstrayId, seedArray[0]);
  
  const bitstray = <Bitstray imgPath={image} alt={description} />;
  return bitstray;
};


export const StandaloneBitstrayWithSeed: React.FC<StandaloneBitstrayWithSeedProps> = (
  props: StandaloneBitstrayWithSeedProps,
) => {
  const { bitstrayId, onLoadSeed, shouldLinkToProfile } = props;

  const seed = useBitstraySeed(bitstrayId);

  if (!seed || !bitstrayId || !onLoadSeed) return <Bitstray imgPath="" alt="Bitstray" />;

  onLoadSeed(seed);
  const { image, description } = getBitstray(bitstrayId, seed);

  const bitstray = <Bitstray imgPath={image} alt={description} />;
  const bitstrayWithLink = (
    <Link to={'/bitstray/' + bitstrayId.toString()} className={classes.clickableBitstray}>
      {bitstray}
    </Link>
  );
  return shouldLinkToProfile ? bitstrayWithLink : bitstray;
};

export default StandaloneBitstray;
