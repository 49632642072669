import { useQuery } from '@apollo/client';
import React from 'react';
import { Image } from 'react-bootstrap';
import _LinkIcon from '../../assets/icons/Link.svg';
import { bitstrayQuery } from '../../wrappers/subgraph';
import _HeartIcon from '../../assets/icons/Heart.svg';
import classes from './BitstrayInfoRowHolder.module.css';

import { buildEtherscanAddressLink } from '../../utils/etherscan';
import ShortAddress from '../ShortAddress';

interface BitstrayInfoRowHolderProps {
  bitstrayId: number;
}

const BitstrayInfoRowHolder: React.FC<BitstrayInfoRowHolderProps> = props => {
  const { bitstrayId } = props;

  const { loading, error, data } = useQuery(bitstrayQuery(bitstrayId.toString()));

  const etherscanURL = buildEtherscanAddressLink(data && data.bitstray.owner.id);

  if (loading) {
    return <p>Loading...</p>;
  } else if (error) {
    return <div>Failed to fetch bitstray info</div>;
  }

  const shortAddressComponent = <ShortAddress address={data && data.bitstray.owner.id} />;

  return (
    <div className={classes.bitstrayHolderInfoContainer}>
      <span>
        <Image src={_HeartIcon} className={classes.heartIcon} />
      </span>
      <span>Held by</span>
      <span>
        <a
          className={classes.bitstrayHolderEtherscanLink}
          href={etherscanURL}
          target={'_blank'}
          rel="noreferrer"
        >
          {shortAddressComponent}
        </a>
      </span>
      <span className={classes.linkIconSpan}>
        <Image src={_LinkIcon} className={classes.linkIcon} />
      </span>
    </div>
  );
};

export default BitstrayInfoRowHolder;
