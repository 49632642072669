import { useContractCall } from '@usedapp/core';
import { BigNumber as EthersBN, utils } from 'ethers';
import { BitstraySantasTokenABI } from '@bitstrays/contracts';
import config from '../config';

export enum BitstraySantasContractFunction {
  publicMint = 'publicMint',
  giftTo = 'giftTo',
}

interface BitstraySantasToken {
  name: string;
  attributes: BitstrayTrait[]
  description: string;
  image: string;
}

interface BitstrayTrait {
  trait_type: string;
  value: string;
}

export interface IBitstraySeed {
  background: number;
  arms: number;
  shirt: number;
  motive: number;
  head: number;
  eyes: number;
  mouth: number;
}

const abi = new utils.Interface(BitstraySantasTokenABI);

export const useBitstraySantasToken = (bitstrayId: EthersBN) => {
  const [bitstray] =
    useContractCall<[string]>({
      abi,
      address: config.addresses.bitstraySantasToken,
      method: 'dataURI',
      args: [bitstrayId],
    }) || [];

  if (!bitstray) {
    return;
  }

  const bitstrayImgData = bitstray.split(';base64,').pop() as string;
  const json: BitstraySantasToken = JSON.parse(atob(bitstrayImgData));

  return json;
};

export const useBitstraySantasTotalSupply = () => {
  const [totalSupply] = useContractCall<[EthersBN]>({
    abi,
    address: config.addresses.bitstraySantasToken,
    method: 'totalSupply'
  }) || [];
  return totalSupply;
};

export const useBitstraySantasPublicMint = () => {
  const tokenId = useContractCall<EthersBN>({
    abi,
    address: config.addresses.bitstraySantasToken,
    method: 'publicMint'
  });
  return tokenId;
};

export const useBitstraySeed = (bitstrayId: EthersBN) => {
  const seed = useContractCall<IBitstraySeed>({
    abi,
    address: config.addresses.bitstraySantasToken,
    method: 'seeds',
    args: [bitstrayId],
  });
  return seed;
};

