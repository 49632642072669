import Section from '../../layout/Section';
import { Col, Row, Card } from 'react-bootstrap';
import classes from './AboutTheTeam.module.css';
import { Twitter } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import misterG from '../../assets/bitstrayer-pfps/MisterG.png'
import gabe from '../../assets/bitstrayer-pfps/Gabe.png'

const misterGtwitter = "https://twitter.com/NotMe_T";
const bitstray0twitter = "https://twitter.com/BushwalkerEth";
const AboutTheTeam = () => {
  return (
    <Section bgColor="white" fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <Row>
          <div className={classes.headerWrapper}>
            <h1>The Bitstray Team</h1>
            <p>We are a team of 2 professionals looking forward to bootstrap web3 community</p>
          </div>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={misterG} />
              <Card.Body>
                <Card.Title >Mister G <Link to={{ pathname:misterGtwitter}} target="_blank"><Twitter className={classes.twitter}/></Link></Card.Title>
                <Card.Text>Founder and artist of this project.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
          <Card>
            <Card.Img variant="top" src={gabe} />
              <Card.Body>
                <Card.Title>Bushwalker Ξth<Link to={{ pathname:bitstray0twitter}} target="_blank"><Twitter className={classes.twitter}/></Link></Card.Title>
                <Card.Text>web3 developer, degen, punk, co-founder</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Section>
  );
};
export default AboutTheTeam;
