import ProofData from '../assets/proof/merkleProof.json';
const proofs = ProofData['proofs'];
const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];

export const getProof = (activeAccount: any) => {
    let proof = [];
    if (activeAccount in proofs) {
        proof = getKeyValue(proofs, activeAccount);
    }
    return (proof);
};

export const isWhitelisted = (activeAccount: any) => {
    return proofs.hasOwnProperty(activeAccount)
};