import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

export const bitstrayQuery = (id: string) => gql`
 {
	bitstray(id:"${id}") {
	  id
	  seed {
	  background
	  arms
	  shirt
	  motive
	  head
	  eyes
	  mouth
	}
	  owner {
		id
	  }
	}
  }
 `;

export const bitstraysIndex = () => gql`
  {
    bitstrays {
      id
      owner {
        id
      }
    }
  }
`;

export const bitstrayVotingHistoryQuery = (bitstrayId: number) => gql`
{
	bitstray(id: ${bitstrayId}) {
		id
		votes {
		proposal {
			id
		}
		support
		}
	}
}
`;

export const highestBitstrayIdMintedAtProposalTime = (proposalStartBlock: number) => gql`
{
	auctions(orderBy: endTime orderDirection: desc first: 1 block: { number: ${proposalStartBlock} }) {
		id
	}
}`;

export const clientFactory = (uri: string) =>
  new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
