import { Col } from 'react-bootstrap';
import classes from './Banner.module.css';
import Section from '../../layout/Section';
import onChainLogo from '../../assets/onchain-logo.svg';

const Banner: React.FC<{
}> = props => {
  return (
    <div>
      <Section bgColor="white" fullWidth={false} className={classes.bannerSection}>
      <Col lg={6}>
      <div className={classes.wrapper}>
          <h1>
            BITSTRAYS,
            <br />
            Looking for a home.
          </h1>
        </div>
        
      </Col>
      <Col lg={6}>
        <img
          src={onChainLogo}
          width="100%"
          className="d-inline-block align-middle"
          alt="etherscan"
        />
      </Col>
      </Section>
    </div>
  );
};

export default Banner;
