import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './FAQ.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';

const Documentation = () => {
  const publicDomainLink = (
    <Link
      text="public domain"
      url="https://creativecommons.org/publicdomain/zero/1.0/"
      leavesPage={true}
    />
  );

  return (
    <Section bgColor="white" fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1 id="faq">FAQ</h1>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>How many mintable NFTs are there?</Accordion.Header>
            <Accordion.Body>
              <p>There will be a total of 10,000 Bitstray NFTs as ERC-721 tokens on the Ethereum blockchain.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              What will be the mint price?
            </Accordion.Header>
            <Accordion.Body>
              <p>The mint price per Bitstray NFT is 0.05 ETH</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              How are Bitstrays different from other NFTs?
            </Accordion.Header>
            <Accordion.Body>
              <p>We believe that there will be a time when pure on-chain NFTs will become
                a sub-culture in the NFT space. Bitstrays will be positioned to stand the test of
                time. 1 year, 10 years even 100 years from now. As long as the Ethereum network
                exists so do Bitstrays. We spend some extra effort and time to develop our
                NFT contract to store all components on-chain. Bitstray trait metadata will be generated
                on-chain, no API or IPFS links required.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              How many Bitstrays can I mint?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                For the public sale every address will be limited to 10 Bitstrays.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              What is on-chain artwork?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                We adopted the Nouns concept which is under {publicDomainLink} license to encode our images.
                Bitstrays are stored directly on Ethereum and do not utilize pointers to other networks such as IPFS.
                This is possible because Bitstray parts are compressed and stored on-chain using a custom run-length encoding (RLE),
                which is a form of lossless compression.
              </p>
              <p>
                The compressed parts are efficiently converted into a single base64 encoded SVG image on-chain.
                To accomplish this, each part is decoded into an intermediate format before being converted into a series of SVG rects using batched, on-chain string concatenation.
                Once the entire SVG has been generated, it is base64 encoded. Additionally, we extended their concept to support
                attribute generation of our Bitstray traits. Those attributes are similar to the image data uploaded and used during
                the NFT generation.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              How are Bitstray seeds generated?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                The Bitstray Seeder contract is used to determine Bitstray traits during the minting
                process. Bitstray traits are determined using pseudo-random number
                generation:
              </p>
              <code>keccak256(abi.encodePacked(blockhash(block.number - 1), bitstrayId))</code>
              <br />
              <br />
              <p>
                We use the randomness once to determine how many heads will be available and then
                a second time to generate the actual trait. This means there will be less common traits.
                In particular any Bistray with a hat will have lower chance to be generated than regular once.
                Every other trait should be somewhat normal distributed.
                Trait generation is not truly random, but we believe that there will be sufficient
                randomness build into our mint process.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
