import { Container, Row } from 'react-bootstrap';
import { useBitstraySantasTotalSupply} from '../../wrappers/bitstraySantasToken';
import ChristmasMint from '../../components/ChristmasMint';
import classes from './Santa.module.css';

interface SantaPageProps {
}

const SantaPage: React.FC<SantaPageProps> = props => {
  const totalSupply = useBitstraySantasTotalSupply();
  return (
    <>
      <ChristmasMint  
        totalSupply={ totalSupply?.toNumber() || 0 }>
      </ChristmasMint>
      <div style={{ backgroundColor: 'red' }}>
        <Container fluid="lg">
          <Row className={classes.message}>
            <p>Wishing you hope, peace and lots of Christmas NFT's gifts this holiday season!</p>
            <p></p>
            <p>Cheers,</p>
            <p>From Bitstrays team :)</p>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default SantaPage;
