import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useEthers } from '@usedapp/core';
import { useAppDispatch, useAppSelector } from './hooks';
import { setActiveAccount } from './state/slices/account';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { setAlertModal } from './state/slices/application';
import classes from './App.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/css/globals.css';
import AlertModal from './components/Modal';
import NavBar from './components/NavBar';
import NetworkAlert from './components/NetworkAlert';
import Footer from './components/Footer';

import Santa from './pages/Santa';
import NotFoundPage from './pages/NotFound';
import { CHAIN_ID } from './config';
import VerifyPage from './pages/Verify';
import ProfilePage from './pages/Profile';
import MainPage from './pages/Main';
import Playground from './pages/Playground';
import { isEnabled } from './components/Feature';

ReactGA.initialize('G-5YW1W9QWMJ');
ReactGA.send(window.location.pathname + window.location.search);

function App() {
  const { account, chainId } = useEthers();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Local account array updated
    dispatch(setActiveAccount(account));
  }, [account, dispatch]);

  const alertModal = useAppSelector(state => state.application.alertModal);
  
  return (
    <div className={`${classes.wrapper}`}>
      {Number(CHAIN_ID) !== chainId && <NetworkAlert />}
      {alertModal.show && (
        <AlertModal
          title={alertModal.title}
          content={<p>{alertModal.message}</p>}
          onDismiss={() => dispatch(setAlertModal({ ...alertModal, show: false }))}
        />
      )}
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/sign" component={VerifyPage} />
          
          <Route exact path="/playground" component={Playground} />
          <Route
            exact
            path="/bitstray/:id"
            render={props => <ProfilePage bitstrayId={Number(props.match.params.id)} />}
          />
          <Route component={NotFoundPage} />
          { isEnabled('santa') ? <Route exact path="/santa" component={Santa} /> : <> </> }
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
