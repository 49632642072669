import classes from './TotalSupply.module.css';
import { Row, Col, ProgressBar } from 'react-bootstrap'

interface TotalSupplyProps {
    totalSupply: number,
    maxSupply: number
}

export const TotalSupply : React.FC<TotalSupplyProps> = props => {
    const { totalSupply, maxSupply } = props;
    return (
      <>
        { (totalSupply > 500) && (
          <Row>
            <Col className={classes.TotalSupplyLabel}>Sale Progress:</Col>
            <Col>
              <ProgressBar className={classes.mintProgress} animated variant="info" max={maxSupply} now={totalSupply} />
              <div className={classes.mintProgressSupply}>{`${totalSupply} / ${maxSupply}`}</div>
            </Col>
          </Row>
        ) }
      </>
    );
};

export default TotalSupply;
