// @ts-nocheck
import { connectContractToSigner, useEthers, useContractFunction } from '@usedapp/core';
import React, { useEffect, useState, useRef, ChangeEvent, useCallback } from 'react';
import { Row, Container, Col, InputGroup, Button, Form } from 'react-bootstrap';
import { Stars } from 'react-bootstrap-icons';
import { utils, BigNumber as EthersBN, errors as EthersErrors } from 'ethers';

import { BitstraysTokenFactory } from '@bitstrays/sdk';

import classes from './Hero.module.css';
import Feature from '../Feature';
import { StandaloneCandidateBitstrayWithSeed } from '../StandaloneBitstray';
import { LoadingBitstray } from '../Bitstray';
import { TotalSupply } from '../TotalSupply';
import { IBitstraySeed, BitstrayContractFunction } from '../../wrappers/bitstrayToken';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { AlertModal, setAlertModal, setStateBackgroundColor } from '../../state/slices/application';
import config from '../../config';
import { getProof } from '../../utils/whitelist';

import { beige, grey, green, blue, creme, pink, purple  } from '../../utils/bitstraysBgColors';

import Ethereum from '../../assets/icons/ethereum-eth-logo.svg';

interface PublicMintProps {
  totalSupply: number;
  mintPrice: EthersBN;
}

const PublicMint: React.FC<PublicMintProps> = props => {
  const activeAccount = useAppSelector(state => state.account.activeAccount);
  const isSale = useAppSelector(state => state.sales.isSaleActive);
  const isPreSale = useAppSelector(state => state.sales.isPreSaleActive);
  const { totalSupply, mintPrice } = props;
  const { library } = useEthers();

  const bitstrayContract = new BitstraysTokenFactory().attach(
    config.addresses.bitstraysToken,
  );

  const { send: placeMint } = useContractFunction(
    bitstrayContract,
    BitstrayContractFunction.publicMint,
  );

  const [mintInput, setMintInput] = useState('0.05');
  const mintInputRef = useRef<HTMLInputElement>(null);

  const [mintQtyInput, setMintQtyInput] = useState('1');
  const mintQtyInputRef = useRef<HTMLSelectElement>(null);

  const bitstrayCandiateId = EthersBN.from(totalSupply)

  //const history = useHistory();
  const dispatch = useAppDispatch();

  const loadedBitstrayHandler = (seed: IBitstraySeed) => {
      let color = grey
      switch (seed.background){
        case 0:
          color = grey;
          break;
        case 1:
          color = beige;
          break;
        case 2:
          color = green;
          break;
        case 3:
          color = blue;
          break;
        case 4:
          color = creme;
          break;
        case 5:
          color = pink;
          break;
        case 6:
          color = purple;
          break;
        default:
          color = grey;
      }
      dispatch(setStateBackgroundColor(color));
  };

  const publicMintContent = (
    <div className={classes.publicMintWrapper}>
      <TotalSupply
        totalSupply={totalSupply}
        maxSupply={10000}
      />
    </div>
  )

  const setModal = useCallback((modal: AlertModal) => dispatch(setAlertModal(modal)), [dispatch]);

  const mintPriceEth = (mintPrice: EthersBN): string => {
    const eth = Number(utils.formatEther(EthersBN.from(mintPrice.toString())));
    return eth.toString();
  };

  const mintInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    // disable more than 2 digits after decimal point
    if (input.includes('.') && event.target.value.split('.')[1].length > 2) {
      return;
    }

    setMintInput(event.target.value);
  };

  const mintQtyInputHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const input = Number(event.target.value);
    setMintQtyInput(event.target.value);
    setMintInput(utils.formatEther(mintPrice.mul(input)));
  };

  const parseError = (error: any) => {
    try {
      console.log(JSON.stringify(error))
      const chainError = error.error ? error.error : null
      if (error.message === 'Internal JSON-RPC error.') {
        const message = error.data ? error.data.message.split("'")[1] : error.message;
        return {
          message: message,
          errorTitle: "Mint Function Error"
        }
      };
      if (chainError) {
        let returnError = {
          message: 'Error',
          errorTitle: "Generic Web3 Error"
        }
        let messageParts = chainError.message.split('Error: ')
        switch (error.code) {
          case EthersErrors.INSUFFICIENT_FUNDS:
            messageParts = chainError.message.split('err: ')
            returnError.message = messageParts[1]
            returnError.errorTitle = "Mint Function Error"
            break;
          case EthersErrors.UNPREDICTABLE_GAS_LIMIT:
            returnError.message = messageParts[1]
            returnError.errorTitle = "Mint Function Error"
            break;
          case EthersErrors.INVALID_ARGUMENT:
            returnError.message = chainError.message
            returnError.errorTitle = "Mint Function Error"
            break;
          default:
            returnError.message = chainError.message
            returnError.errorTitle = "Smart Contract Error"
            console.log(chainError)
            break;
        }
        return returnError
      };
      // all other error cases
      return {
        message: <p>{error.message.split(' (')[0]}</p>,
        errorTitle: 'Error'
      }
    } catch (error: any) {
      console.log(error)
      return {
        message: <p>{error.message}</p>,
        errorTitle: 'Error'
      }
    }
  }

  const placeMintHandler = async () => {
    if (!mintInputRef.current || !mintInputRef.current.value) {
      return;
    }
    const proof: string[] = getProof(activeAccount);
    const amount = mintInputRef.current.value;
    const value = utils.parseEther((amount).toString());
    const contract = connectContractToSigner(bitstrayContract, undefined, library);
    try {
      const gasLimit = await contract.estimateGas.publicMint(mintQtyInput, proof, {
        value,
      });
      placeMint(mintQtyInput, proof, {
        value,
        gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
      });

    } catch (error: any) {
      const { message, errorTitle } = parseError(error)
      setModal({
        title: errorTitle,
        message: message,
        show: true,
      });
    }
  }

  useEffect(() => {
    if (mintInputRef.current?.value === '0') {
      setMintInput(mintPriceEth(mintPrice));
    }
  }, [mintPrice])

  const bitstrayContent = (
    <div className={classes.bitstrayWrapper}>
      <StandaloneCandidateBitstrayWithSeed
        bitstrayId={bitstrayCandiateId}
        onLoadSeed={loadedBitstrayHandler}
        shouldLinkToProfile={false}
      />
    </div>
  );

  const optionPreSale = (
    <>
      <option>1</option>
      <option>2</option>
      <option>3</option>
    </>
  )

  const optionSale = (
    <>
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
      <option>6</option>
      <option>7</option>
      <option>8</option>
      <option>9</option>
      <option>10</option>
    </>
  )

  const mintQty = (preSales: boolean, sales: boolean) => {
    if (preSales && !sales) {
      return optionPreSale
    }
    return optionSale
  };

  const isDisabled = !activeAccount;

  const loadingBitstray = (
    <div className={classes.bitstrayWrapper}>
      <LoadingBitstray />
    </div>
  );

  const EthereumImg = (
    <img
      src={Ethereum}
      width="20"
      height="20"
      className="d-inline-block align-middle"
      alt="Bitstrays logo"
    />
  )

  const publicMint = (
    <div className={classes.publicMintCol}>
      <h1 className={classes.mintTitle}>'Adopt yours!'</h1>
      <br/><br/>
      {publicMintContent}
      <Row>
        <Col className={classes.mintFormLabel}>
          Mint price:
        </Col>
        <Col className={classes.mintPrice}>{mintPriceEth(mintPrice)} {EthereumImg} <spam className={classes.mintEth}>ETH</spam></Col>
      </Row>
      <hr />
      <Row>
        <Col lg={{ span: 6 }}>
          <Form.Label className={classes.mintFormLabel}>How Many?</Form.Label>
        </Col>
        <Col lg={{ span: 6 }}>
          <Form.Control
            as="select"
            onChange={mintQtyInputHandler}
            ref={mintQtyInputRef}
            value={mintQtyInput}
            type="number"
            size="lg"
          >
            {mintQty(isPreSale, isSale)}
          </Form.Control>
        </Col>
      </Row>
          
          <Row>
            <Col lg={{ span: 6 }}>
              <Form.Label className={classes.mintFormLabel}>Total</Form.Label>
            </Col>
            <Col lg={{ span: 6 }}>
              <InputGroup>
                <Form.Control
                  className={classes.bidInput}
                  type="number"
                  min="0"
                  onChange={mintInputHandler}
                  ref={mintInputRef}
                  value={mintInput}
                  readOnly
                  size="lg"
                />
                <span className={classes.customPlaceholder}>ETH</span>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6}></Col>
            <Col lg={6}>
              <Button onClick={placeMintHandler} type="button" variant="danger" size="lg" className={[classes.btn3d, classes.btnDanger]} disabled={isDisabled}>
                <Stars/> Mint
              </Button>
            </Col>
          </Row>
    </div>
  );

  let stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);
  return (
    <div style={{ backgroundColor: stateBgColor }}>
      <Feature name={'santa'}>
        <div className={classes.snowflakes} >
          {
            ['b','i','t','s','t','r','a','i', 's'].map(()=><div className={classes.snowflake}>❅</div>)
          }
        </div>
      </Feature>
      <Container fluid="lg">
      <Row>
        <div class="snow"></div>
        <Col lg={{ span: 6 }} className={classes.bitstrayContentCol}>
          {bitstrayContent ? bitstrayContent : loadingBitstray}
        </Col>
        <Col lg={{ span: 6 }}>
          {publicMint}
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default PublicMint;
