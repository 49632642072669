import { BigNumber } from 'ethers';
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { StandaloneBitstrayWithSeed } from '../../components/StandaloneBitstray';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setStateBackgroundColor } from '../../state/slices/application';
import { IBitstraySeed } from '../../wrappers/bitstrayToken';
import classes from './Profile.module.css';
import { beige, grey, green, blue, creme, pink, purple } from '../../utils/bitstraysBgColors';

import BitstrayInfoCard from '../../components/BitstrayInfoCard';

interface ProfilePageProps {
  bitstrayId: number;
}

const ProfilePage: React.FC<ProfilePageProps> = props => {
  const { bitstrayId } = props;
  const dispatch = useAppDispatch();

  const loadedBitstrayHandler = (seed: IBitstraySeed) => {
    let color = grey;
    switch (seed.background){
      case 0:
        color = grey;
        break;
      case 1:
        color = beige;
        break;
      case 2:
        color = green;
        break;
      case 3:
        color = blue;
        break;
      case 4:
        color = creme;
        break;
      case 5:
        color = pink;
        break;
      case 6:
        color = purple;
        break;
      default:
        color = grey;
    }
    dispatch(setStateBackgroundColor(color));
  };

  const bitstrayIdForDisplay = Math.min(bitstrayId);

  const bitstrayContent = (
    <StandaloneBitstrayWithSeed
      bitstrayId={BigNumber.from(bitstrayIdForDisplay)}
      onLoadSeed={loadedBitstrayHandler}
      shouldLinkToProfile={false}
    />
  );
  let stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);
  return (
    <div style={{ backgroundColor: stateBgColor }}>
      <Container fluid="lg">
        <Row>
          <Col lg={6}>{bitstrayContent}</Col>
          <Col lg={6} className={classes.bitstrayProfileInfo}>
            <BitstrayInfoCard bitstrayId={bitstrayIdForDisplay} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfilePage;
