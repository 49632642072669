import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { useAppDispatch } from './hooks';
import reportWebVitals from './reportWebVitals';
import { ChainId, DAppProvider } from '@usedapp/core';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import account from './state/slices/account';
import application from './state/slices/application';
import logs from './state/slices/logs';
import { BitstraysTokenFactory, BitstraySantasTokenFactory } from '@bitstrays/sdk';
import { ApolloProvider } from '@apollo/client';
import { clientFactory } from './wrappers/subgraph';
import LogsUpdater from './state/updaters/logs';
import config, { CHAIN_ID, createNetworkHttpUrl } from './config';
import { WebSocketProvider } from '@ethersproject/providers';
import dotenv from 'dotenv';
import { ConnectedRouter, connectRouter } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { applyMiddleware, createStore, combineReducers, PreloadedState } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import sales, { setIsSaleActive, setIsPreSaleActive, setLastSaleId } from './state/slices/sales';
import santa, { setIsMintActive } from './state/slices/santa';

dotenv.config();

export const history = createBrowserHistory();

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    account,
    sales,
    application,
    santa,
    logs
  });

export default function configureStore(preloadedState: PreloadedState<any>) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        // ... other middlewares ...
      ),
    ),
  );

  return store;
}

const store = configureStore({});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// prettier-ignore
const useDappConfig = {
  readOnlyChainId: CHAIN_ID,
  readOnlyUrls: {
    [ChainId.Rinkeby]: createNetworkHttpUrl('rinkeby'),
    [ChainId.Mainnet]: createNetworkHttpUrl('mainnet'),
    [ChainId.Hardhat]: 'http://localhost:8545',
  },
};

const client = clientFactory(config.app.subgraphApiUri);

const Updaters = () => {
  return (
    <>
      <LogsUpdater />
    </>
  );
};

const ChainSubscriber: React.FC = () => {
  const dispatch = useAppDispatch();
  const loadState = async () => {
    const wsProvider = new WebSocketProvider(config.app.wsRpcUri);
    const bitstraySantasTokenContract = BitstraySantasTokenFactory.connect(
      config.addresses.bitstraySantasToken,
      wsProvider,
    );
    const bitstraysTokenContract = BitstraysTokenFactory.connect(
      config.addresses.bitstraysToken,
      wsProvider,
    );
    const isSalesActive = await bitstraysTokenContract.isSaleActive()
    const isPresaleActive = await bitstraysTokenContract.isPresaleActive()
    const lastSaleId = await bitstraysTokenContract.totalSupply()
    const isSantaMint =  await bitstraySantasTokenContract.isMintActive()
    dispatch(setIsSaleActive(isSalesActive));
    dispatch(setIsMintActive(isSantaMint));
    dispatch(setIsPreSaleActive(isPresaleActive));  
    dispatch(setLastSaleId(lastSaleId.toNumber()));
  };
  loadState();

  return <></>;
};

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ChainSubscriber />
      <React.StrictMode>
        <Web3ReactProvider
          getLibrary={
            provider => new Web3Provider(provider) // this will vary according to whether you use e.g. ethers or web3.js
          }
        >
          <ApolloProvider client={client}>
            <DAppProvider config={useDappConfig}>
              <App />
              <Updaters />
            </DAppProvider>
          </ApolloProvider>
        </Web3ReactProvider>
      </React.StrictMode>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
