"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractsForChainOrThrow = void 0;
const contracts_1 = require("@bitstrays/contracts");
const addresses_1 = require("./addresses");
/**
 * Get contract instances that target the Ethereum mainnet
 * or a supported testnet. Throws if there are no known contracts
 * deployed on the corresponding chain.
 * @param chainId The desired chain id
 * @param signerOrProvider The ethers v5 signer or provider
 */
const getContractsForChainOrThrow = (chainId, signerOrProvider) => {
    const addresses = addresses_1.getContractAddressesForChainOrThrow(chainId);
    return {
        bitstraysTokenContract: contracts_1.BitstraysTokenFactory.connect(addresses.bitstraysToken, signerOrProvider),
        bitstraySantasTokenContract: contracts_1.BitstraySantasTokenFactory.connect(addresses.bitstraySantasToken, signerOrProvider),
        bitstraysDescriptorContract: contracts_1.BitstraysDescriptorFactory.connect(addresses.bitstraysDescriptor, signerOrProvider),
        bitstraysSeederContract: contracts_1.BitstraysSeederFactory.connect(addresses.bitstraysSeeder, signerOrProvider),
        bitstraySantasSeederContract: contracts_1.BitstraySantasSeederFactory.connect(addresses.bitstraySantasSeeder, signerOrProvider),
        bitstraysDaoContract: contracts_1.BitstraysDaoLogicV1Factory.connect(addresses.bitstraysDAOProxy, signerOrProvider),
    };
};
exports.getContractsForChainOrThrow = getContractsForChainOrThrow;
