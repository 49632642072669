import React from 'react';

interface FeatureProps {
  name: String
}

const features = [
  { name: 'santa', description: 'Special santa collection', active: false }
]

const Feature: React.FC<FeatureProps> = (props) => {
  const { name, children } = props;
  const feature = features.find((feature: any) => feature.name === name);

  if (feature && feature.active) {
    return <>{children}</>;
  }
  return null;
};

export const isEnabled = (name: String) => {
  const feature = features.find((feature: any) => feature.name === name);
  return (feature && feature.active)
}

export default Feature;





